var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.latestPrivacyPolicy && _vm.isVisible
    ? _c(
        "modal",
        {
          attrs: {
            headline: _vm.$t("privacyPolicyModal.headline"),
            closable: false,
          },
        },
        [
          _c("div", { staticClass: "ce-modalContent" }, [
            _c("div", {
              domProps: {
                innerHTML: _vm._s(_vm.latestPrivacyPolicy.description),
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "mt4 flex flex-wrap mxn1" }, [
              _c(
                "button",
                {
                  staticClass: "ce-btn ce-btn-light m1",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.accept.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("privacyPolicyModal.acceptCta")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "ce-btn ce-btn-grey m1",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.reject.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("privacyPolicyModal.rejectCta")) +
                      "\n      "
                  ),
                ]
              ),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }