<template>
  <modal
    v-if="latestPrivacyPolicy && isVisible"
    :headline="$t('privacyPolicyModal.headline')"
    :closable="false">
    <div class="ce-modalContent">
      <div v-html="latestPrivacyPolicy.description" />

      <div class="mt4 flex flex-wrap mxn1">
        <button
          class="ce-btn ce-btn-light m1"
          @click.prevent="accept">
          {{ $t('privacyPolicyModal.acceptCta') }}
        </button>

        <button
          class="ce-btn ce-btn-grey m1"
          @click.prevent="reject">
          {{ $t('privacyPolicyModal.rejectCta') }}
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapState('generic', ['latestPrivacyPolicy']),
    ...mapState('user', ['activeUser', 'logoutUrl']),
    ...mapGetters('user', ['isInternal']),
    privacyPolicyStates() {
      return this.activeUser?.privacyPolicyStates;
    },
    latestPrivacyPolicyAccepted() {
      if (this.privacyPolicyStates && Array.isArray(this.privacyPolicyStates)) {
        return !!this.privacyPolicyStates.find((row) => row.version === this.latestPrivacyPolicy.version);
      }

      return false;
    },
    isVisible() {
      if (this.activeUser && !this.isInternal && !this.latestPrivacyPolicyAccepted) {
        return true;
      }

      return false;
    }
  },
  methods: {
    ...mapActions('user', ['acceptPrivacyPolicy']),
    async reject() {
      location.href = this.logoutUrl;
    },
    async accept() {
      await this.acceptPrivacyPolicy({ version: this.latestPrivacyPolicy.version });
    }
  },
};
</script>

<style lang="less">
@import (reference) '~styles/includes/base';
</style>
