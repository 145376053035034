<template>
  <ValidationProvider
    ref="validationProvider"
    :name="validatorName"
    v-slot="{ errors }"
    :rules="rules"
    :mode="mode"
    tag="div"
    class="ce-field">
    <label
      v-if="label"
      :for="name">
      {{ label }} <span v-if="isRequired && showRequiredIndicator">*</span>
      <span
        v-if="errors.length > 0"
        class="ms-2 ml1 regular ce-colorError">
        {{ errors[0] }}
      </span>
    </label>

    <div
      v-if="description"
      class="ce-field-description">
      {{ description }}
    </div>

    <div
      class="ce-field-inputWrapper"
      :class="{ invalid: errors.length > 0 }">
      <input
        v-if="type !== 'textarea'"
        ref="input"
        :id="name"
        :type="activeInputType"
        :name="name"
        :value="value"
        :placeholder="placeholder"
        :disabled="disabled"
        :autocomplete="autocomplete"
        :autocapitalize="autocapitalize"
        @input="$emit('input', $event)"
        v-on="$listeners">

      <i
        class="ce-field-passwordToggle"
        v-if="type === 'password'"
        @click.prevent="togglePasswordVisibility">
        <fa-icon
          v-if="activeInputType === 'password'"
          :icon="['fas', 'eye']" />
        <fa-icon
          v-else
          :icon="['fas', 'eye-slash']" />
      </i>

      <textarea
        v-if="type === 'textarea'"
        ref="input"
        :id="name"
        :name="name"
        :value="value"
        :placeholder="placeholder"
        :disabled="disabled"
        @input="$emit('input', $event)"
        v-on="$listeners" />
    </div>
  </ValidationProvider>
</template>

<script>
export default {
  props: {
    value: {
      // eslint-disable-next-line no-bitwise
      type: String | Number,
      required: true
    },
    name: {
      type: String,
      required: true
    },

    label: {
      type: String,
      required: true
    },

    description: {
      type: String,
      required: false,
      default: null
    },

    placeholder: {
      type: String,
      required: false,
      default: null
    },

    validatorName: {
      type: String,
      required: false,
      default() {
        return this.label;
      }
    },

    rules: {
      type: String,
      required: false,
      default: null
    },

    mode: {
      type: String,
      required: false,
      default: 'eager'
    },

    type: {
      type: String,
      required: false,
      default: 'text'
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false
    },

    showRequiredIndicator: {
      type: Boolean,
      required: false,
      default: true
    },

    autocomplete: {
      type: String,
      required: false,
      default: ''
    },

    autocapitalize: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      activeInputType: null
    };
  },
  created() {
    this.activeInputType = this.type;
  },
  computed: {
    isRequired() {
      return this.rules && this.rules.indexOf('required') !== -1;
    }
  },
  methods: {
    togglePasswordVisibility() {
      if (this.activeInputType === 'password') {
        this.activeInputType = 'text';
      } else {
        this.activeInputType = 'password';
      }
    },
    focus() {
      this.$refs.input.focus();
    },
    async validate() {
      return this.$refs.validationProvider.validate();
    }
  }
};
</script>
