<template>
  <modal
    :headline="$t('changeInitialPasswordModal.headline')"
    :description="$t('changeInitialPasswordModal.description')"
    class="ce-changeInitialPassword"
    :closable="false">
    <div class="ce-modalContent">
      <ValidationObserver
        ref="validationObserver"
        tag="div">
        <password-field
          name="password"
          :value="form.password"
          @input="onInput"
          :label="$t('changeInitialPasswordModal.password')" />

        <div>
          <button
            class="ce-btn ce-btn-light ce-btn--fullWidth"
            @click.prevent="submit">
            <template v-if="loading">
              <fa-icon
                icon="spinner"
                spin />
            </template>
            <template v-else>
              {{ $t('changeInitialPasswordModal.submit') }}
            </template>
          </button>
        </div>
      </ValidationObserver>
    </div>
  </modal>
</template>

<script>
import PasswordField from 'components/forms/PasswordField.vue';

export default {
  components: { PasswordField },
  data() {
    return {
      form: {
        password: null
      },
      loading: false
    };
  },
  methods: {
    async submit() {
      const validationResult = await this.$refs.validationObserver.validate();
      if (!validationResult) return;

      this.loading = true;
      await this.$store.dispatch('user/changePassword', this.form.password);
      this.loading = false;
    }
  }
};
</script>

<style lang="less">
  @import (reference) '~styles/includes/base';
</style>
