<template>
  <modal
    @close="close"
    :headline="$t('register.modalHeadline')"
    :description="$t('register.modalDescription')">
    <div class="ce-registerForm">
      <div class="ce-modalContent ce-userRegistration-content">
        <alert
          v-if="hasMessage"
          :message="message.content"
          :type="message.type"
          :closable="false" />

        <template v-if="finished">
          <p class="mt0">
            {{ $t('register.successContent') }}
          </p>

          <div class="mt4">
            <button
              class="ce-btn ce-btn-light ce-btn--fullWidth"
              @click.prevent="close">
              {{ $t('register.successCta') }}
            </button>
          </div>
        </template>

        <template v-if="!finished">
          <ValidationObserver
            ref="validationObserver"
            tag="div">
            <validated-input
              name="firstName"
              :value="form.firstName"
              @input="onInput"
              :label="$t('register.firstName')"
              rules="required" />

            <validated-input
              name="lastName"
              :value="form.lastName"
              @input="onInput"
              :label="$t('register.lastName')"
              rules="required" />

            <validated-input
              name="company"
              :value="form.company"
              @input="onInput"
              :label="$t('register.company')"
              rules="required" />

            <validated-input
              name="email"
              :value="form.email"
              @input="onInput"
              :label="$t('register.email')"
              rules="required|email" />

            <country-select
              v-model="form.country"
              name="country"
              rules="required"
              :label="$t('countrySelect.label')" />

            <ValidationProvider
              :name="$t('register.industry')"
              v-slot="{ errors }"
              rules="required"
              mode="eager"
              tag="div"
              class="ce-field">
              <label>
                {{ $t('register.industry') }} <span>*</span>
                <span
                  v-if="errors.length > 0"
                  class="ms-2 ml1 regular ce-colorError">
                  {{ errors[0] }}
                </span>
              </label>
              <div class="flex flex-wrap mxn2">
                <div
                  class="ce-userRegistration-industrySelect"
                  v-for="industry in industries"
                  :key="industry.code">
                  <input
                    type="checkbox"
                    :value="industry.code"
                    :id="`industry_${industry.code}`"
                    v-model="form.selectedIndustries">

                  <label :for="`industry_${industry.code}`">
                    {{ industry.title }}
                  </label>
                </div>
              </div>
            </ValidationProvider>

            <password-field
              name="password"
              :value="form.password"
              @input="onInput"
              :label="$t('register.password')" />

            <div class="ce-userRegistration-consent ms-1 line-height-3 mb4">
              <div>
                {{ $t('register.consent.infoContent') }}
              </div>
              <div class="ce-userRegistration-consent-personalData">
                <p>{{ $t('register.consent.personalData.topInfo') }}</p>

                <div class="mt2">
                  <checkbox
                    class="ce-field"
                    id="consent_inquiries"
                    v-model="form.consent.inquiries"
                    :vertical-centered="false">
                    {{ $t('register.consent.personalData.inquiries') }}
                  </checkbox>
                  <checkbox
                    class="ce-field"
                    id="consent_personalizedTracking"
                    v-model="form.consent.personalizedTracking"
                    :vertical-centered="false">
                    {{ $t('register.consent.personalData.personalizedTracking') }}

                    <a
                      :href="links.sunChemicalDataProtectionPage ?? links.dataProtectionPage"
                      class="ce-userRegistration-consent-infoCta"
                      @click.prevent="consentInfoContent = $t('register.consent.dataProtectionInfo.personalizedTracking')">
                      {{ $t('register.consent.dataProtectionInfo.label') }}
                    </a>.
                  </checkbox>
                  <checkbox
                    class="ce-field"
                    id="consent_surveys"
                    v-model="form.consent.surveys"
                    :vertical-centered="false">
                    {{ $t('register.consent.personalData.surveys') }}

                    <a
                      :href="links.sunChemicalDataProtectionPage ?? links.dataProtectionPage"
                      class="ce-userRegistration-consent-infoCta"
                      @click.prevent="consentInfoContent = $t('register.consent.dataProtectionInfo.surveys')">
                      {{ $t('register.consent.dataProtectionInfo.label') }}
                    </a>.
                  </checkbox>

                  <checkbox
                    class="ce-field"
                    id="consent_onlineMarketing"
                    v-model="form.consent.onlineMarketing"
                    :vertical-centered="false">
                    {{ $t('register.consent.personalData.onlineMarketing') }}

                    <a
                      :href="links.sunChemicalDataProtectionPage ?? links.dataProtectionPage"
                      class="ce-userRegistration-consent-infoCta"
                      @click.prevent="consentInfoContent = $t('register.consent.dataProtectionInfo.onlineMarketing')">
                      {{ $t('register.consent.dataProtectionInfo.label') }}
                    </a>.
                  </checkbox>
                </div>

                <p>{{ $t('register.consent.personalData.disclaimer') }}</p>

                <modal
                  :show-header="false"
                  v-if="consentInfoContent"
                  @close="consentInfoContent = null">
                  <div class="ce-modalContent">
                    <div v-html="consentInfoContent" />

                    <div>
                      <i18n path="register.consent.fullDataProtectionStatement.content">
                        <a
                          class="ce-userRegistration-consent-infoCta"
                          target="_blank"
                          :href="links.sunChemicalDataProtectionPage ?? links.dataProtectionPage">
                          {{ $t('register.consent.fullDataProtectionStatement.link') }}
                        </a>
                      </i18n>
                    </div>
                  </div>
                </modal>
              </div>
            </div>

            <div>
              <button
                class="ce-btn ce-btn-light ce-btn--fullWidth"
                @click.prevent="submit">
                <template v-if="loading">
                  <fa-icon
                    icon="spinner"
                    spin />
                </template>
                <template v-else>
                  {{ $t('register.registerCta') }}
                </template>
              </button>
            </div>

            <div class="mt2 ce-userRegistration-login">
              {{ $t('register.existingAccount') }} <a
                :href="loginLink"
                @click.prevent="$store.dispatch('user/showLogin')">{{ $t('register.loginCta') }}</a>
            </div>
          </ValidationObserver>
        </template>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';
import Alert from 'components/Alert.vue';
import Checkbox from 'components/Checkbox.vue';
import PasswordField from 'components/forms/PasswordField.vue';

export default {
  components: {
    Alert, Checkbox, PasswordField
  },
  data() {
    return {
      form: {
        firstName: null,
        lastName: null,
        company: null,
        email: null,
        country: null,
        selectedIndustries: [],
        password: null,
        consent: {
          inquiries: false,
          personalizedTracking: false,
          surveys: false,
          onlineMarketing: false
        }
      },
      message: {
        type: 'success',
        content: null
      },
      finished: false,
      loading: false,
      consentInfoContent: null
    };
  },
  computed: {
    ...mapState('generic', ['industries', 'links']),
    hasMessage() {
      return this.message && this.message.content;
    },
    loginLink() {
      return `${location.origin}${location.pathname}?showLogin=1`;
    }
  },
  methods: {
    async submit() {
      const validationResult = await this.$refs.validationObserver.validate();
      if (!validationResult) return;

      // Map consent object to simple array
      const data = this.$cloneObject(this.form);
      data.consent = Object.keys(this.form.consent).filter((consent) => this.form.consent[consent]);

      this.loading = true;
      this.message = {};
      this.$trackEvent('user', 'register', false);
      try {
        await this.$store.dispatch('user/register', data);
        this.finished = true;
      } catch (e) {
        this.message = {
          type: 'error',
          content: e
        };
      }
      this.loading = false;
    },
    close() {
      this.$store.dispatch('user/hideRegistration');
    }
  }
};
</script>

<style lang="less">
  @import (reference) '~styles/includes/base';

  .ce-userRegistration-content {
    > div {
      .mb3;
    }

    .ce-pageHeader .ce-pageHeader-main & {
      .ce-userRegistration-login {
        a {
          color: @color-light;
        }
      }
    }

    .ce-userRegistration-consent-personalData {
      > p {
        &:first-child {
          margin-bottom: @space-1;
        }
        &:last-child {
          margin-top: @space-1;
        }
      }

      > div {
        line-height: 1.5;

        > div {
          display: flex;
          align-items: center;

          input {
            margin-right: @space-1;
          }
        }
      }
    }

    .ce-userRegistration-industrySelect {
      display: flex;
      align-items: center;
      margin: @space-2;

      label {
        margin: 0;
        .noselect;
      }

      input {
        margin-right: @space-1;
      }
    }

    .ce-userRegistration-consent {
      .ce-field.ce-checkbox {
        margin-bottom: @space-2;
      }

      .ce-userRegistration-consent-infoCta {
        font-weight: 400;
        text-decoration: underline;
        color: @color-text-base !important;
      }

      .tooltip {
        .tooltip-inner {
          max-width: 100%;
          .ms(-2);
          font-weight: 400;
        }
      }
    }
  }
</style>
