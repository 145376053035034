import Cookies from 'js-cookie';
import qs from 'qs';
import { cloneObject } from 'utils';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';

export const namespaced = true;

const state = {
  industries: cloneObject(payload.generic.industries),
  actionMessage: payload.generic.actionMessage ? cloneObject(payload.generic.actionMessage) : null,
  contactRegions: payload.generic.contactRegions ? cloneObject(payload.generic.contactRegions) : null,
  formSecurityToken: payload.generic.formSecurityToken,
  mobileMenuVisible: false,
  mobileMainMenu: payload.generic.mobileMainMenu ? cloneObject(payload.generic.mobileMainMenu) : null,
  cookieModalVisible: false,
  cookieSettings: {},
  links: payload.generic.links ? cloneObject(payload.generic.links) : null,
  overlayStack: [],
  services: payload.services ? cloneObject(payload.services) : null,
  userMenu: {
    staticLinks: payload.generic.userMenu.staticLinks
  },
  changedMySheets: null,
  disableQrCodes: payload.generic?.disableQrCodes || false,
  pagePermissions: payload.generic?.pagePermissions ? cloneObject(payload.generic.pagePermissions) : null,
  requestCountry: payload.generic?.requestCountry ?? null,
  countryList: null,
  latestPrivacyPolicy: payload.generic?.latestPrivacyPolicy ? cloneObject(payload.generic.latestPrivacyPolicy) : null,
};

/**
 * Init the cookie settings handling.
 *
 * Check for stored cookie settings, try to load and validate them.
 * Show the cookie modal if there are no settings stored or the stored data is invalid.
 *
 * @param store
 */
const initCookieSettings = (store) => {
  if (Cookies.get('ce-cookieSettings')) {
    try {
      const cookieSettings = JSON.parse(Cookies.get('ce-cookieSettings'));

      if (typeof cookieSettings === 'object'
        && Object.keys(cookieSettings).length === 3) {
        const cookieKeys = Object.keys(cookieSettings);

        ['mandatory', 'functional', 'marketing'].forEach((cookieType) => {
          if (cookieKeys.indexOf(cookieType) === -1) {
            throw new Error(`missing setting for cookie type ${cookieType}`);
          }
        });

        store.dispatch('generic/setCookieSettings', cookieSettings);
      } else {
        throw new Error('invalid cookie settings format');
      }
    } catch (e) {
      Cookies.remove('ce-cookieSettings');
      state.cookieModalVisible = true;
    }
  } else {
    state.cookieModalVisible = true;
  }

  // Hide the modal per default on the data protection page
  if (state.cookieModalVisible
    && state.links
    && state.links.dataProtectionPage
    && state.links.dataProtectionPage === location.pathname) {
    state.cookieModalVisible = false;
  }
};

// Generic module init function, ATTENTION: The user module has to be initiated first
const init = (store) => {
  if (payload.generic.suppressCookieModal !== true) {
    initCookieSettings(store);
  }

  // Listen for hashchange to #cookiemodal, allows showing the modal on every page using that anchor/hash
  window.onhashchange = () => {
    const { hash } = location;
    if (hash === '#cookiesettings') {
      store.dispatch('generic/showCookieModal');
      history.replaceState({}, document.title, location.href.replace(hash, ''));
    }
  };

  // Show the cookie modal if there is a "showCookieSettings" GET param
  if (!store.state.generic.cookieModalVisible) {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (typeof params.showCookieSettings !== 'undefined') {
      store.dispatch('generic/showCookieModal');
    }
  }
};

export {
  state,
  getters,
  actions,
  mutations,
  init
};
