const map = {
  result_type: {
    param: 'type',
    method: 'addResultTypeFilter',
    allowedOptions: ['pigments', 'assets', 'imageStacks'],
  },
  stack_file_version: {
    param: 'stackFileVersion',
    method: 'addStackFileVersionFilter',
  },
  search_term: {
    param: 'q',
    method: 'addSearchTermFilter',
  },
  industry: {
    param: 'i',
    method: 'addIndustryFilter',
  },
  brand: {
    param: 'brand',
    method: 'addBrandFilter',
  },
  color_space: {
    param: 'c',
    method: 'addColorFilter',
  },
  product_group: {
    param: 'pg',
    method: 'addProductGroupFilter',
  },
  transparency: {
    param: 't',
    method: 'addTransparencyFilter',
  },
  performance: {
    param: 'p',
    method: 'addPerformanceFilter',
  },
  pigment_effect: {
    param: 'pe',
    method: 'addPigmentEffectFilter',
  },
  form: {
    param: 'f',
    method: 'addFormFilter',
  },
  asset_type: {
    param: 'asset_type',
    method: 'addAssetTypeFilter',
  },
  min_document_created_date: {
    param: 'min_doc_date',
    method: 'addMinCreatedDateFilter',
  },
  application: {
    param: 'application',
    method: 'addApplicationFilter',
  },
  coating_industry: {
    param: 'c_i',
    method: 'addCoatingIndustryFilter',
  },
  appearance: {
    param: 'appearance',
    method: 'addAppearanceFilter',
  },
  coating_system: {
    param: 'c_s',
    method: 'addCoatingSystemFilter',
  },
  substrate: {
    param: 'substrate',
    method: 'addSubstrateFilter',
  },
  warping: {
    param: 'warping',
    method: 'addWarpingFilter',
  },
  printing_technology: {
    param: 'p_t',
    method: 'addPrintingTechnologyFilter',
  },
  ink_system: {
    param: 'ink_system',
    method: 'addPrintingInkSystemFilter',
  },
  toner_system: {
    param: 'toner_system',
    method: 'addPrintingTonerSystemFilter',
  },
  dispersibility: {
    param: 'dispersibility',
    method: 'addDispersibilityFilter',
  },
  rheology: {
    param: 'rheology',
    method: 'addRheologyFilter',
  },
  light_fastness: {
    param: 'light_fastness',
    method: 'addLightFastnessFilter',
  },
  food_contact_support: {
    param: 'food_contact',
    method: 'addFoodContactFilter',
  },
  natural_content: {
    param: 'natural_content',
    method: 'addNaturalContentFilter',
  },
  natural_origin: {
    param: 'natural_origin',
    method: 'addNaturalOriginFilter',
  },
  specific_requirements: {
    param: 'sr',
    method: 'addSpecificRequirementsFilter',
  },
  sustainability: {
    param: 'sustainability',
    method: 'addSustainabilityFilter',
  },
  regulatory: {
    param: 'regulatory',
    method: 'addRegulatoryFilter',
  },
  production_plants: {
    param: 'prod_plant',
    method: 'addProductionPlantsFilter',
  },
  competitor: {
    param: 'competitor',
    method: 'addCompetitorFilter',
  },
  color_index: {
    param: 'color_index',
    method: 'addColorIndexFilter',
  },
  chemistry: {
    param: 'chemistry',
    method: 'addChemistryFilter',
  },
  ids: {
    param: 'ids',
    method: 'addIDsFilter',
  },
  excludedIds: {
    param: 'excluded_ids',
    method: 'addExcludedIdsFilter',
  },
  product_ids: {
    param: 'product_ids',
    method: 'addProductIDsFilter',
  },
  vegan: {
    param: 'vegan',
    method: 'addVeganFilter',
  },
  halal_certification: {
    param: 'halal',
    method: 'addHalalCertificationFilter',
  },
  min_created_date: {
    param: 'min_date',
    method: 'addMinCreatedDateFilter',
  },
  passivated: {
    param: 'passivated',
    method: 'addPassivatedFilter',
  },
  topic: {
    param: 'topic',
    method: 'addTopicFilter',
  },
  tag: {
    param: 'tag',
    method: 'addTagFilter',
  },
  tab: {
    param: 'tab',
    method: 'addTabFilter',
  },
  ingredient: {
    param: 'ingredient',
    method: 'addIngredientFilter',
  },
  zdhc_certification: {
    param: 'zdhc',
    method: 'addZdhcCertificationFilter',
  },
  hide_internal_products: {
    param: 'no_internal',
    method: 'addHideInternalProductFilter',
  },
};

class SearchConfig {
  static getMap() {
    return map;
  }

  static getKeys() {
    return Object.keys(map);
  }

  static getParams() {
    return Object.values(map).map((val) => val.param);
  }

  static getMethods() {
    return Object.values(map).map((val) => val.method);
  }

  static configForParam(param) {
    const key = Object
      .keys(map)
      .find((k) => map[k].param === param);

    return {
      key,
      ...map[key],
    };
  }

  static getParamsMap() {
    const paramsMap = {};

    this.getKeys().forEach((key) => {
      paramsMap[key] = this.getMap()[key].param;
    });

    return paramsMap;
  }

  static getInvertedParamsMap() {
    return this.getKeys().reduce(
      (invertedMap, key) => ({ ...invertedMap, [map[key].param]: key }),
      {},
    );
  }
}

module.exports = SearchConfig;
