var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ValidationProvider", {
    ref: "validationProvider",
    staticClass: "ce-field",
    attrs: {
      name: _vm.validatorName,
      rules: _vm.rules,
      mode: _vm.mode,
      tag: "div",
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ errors }) {
          return [
            _vm.label
              ? _c("label", { attrs: { for: _vm.name } }, [
                  _vm._v("\n    " + _vm._s(_vm.label) + " "),
                  _vm.isRequired && _vm.showRequiredIndicator
                    ? _c("span", [_vm._v("*")])
                    : _vm._e(),
                  _vm._v(" "),
                  errors.length > 0
                    ? _c(
                        "span",
                        { staticClass: "ms-2 ml1 regular ce-colorError" },
                        [_vm._v("\n      " + _vm._s(errors[0]) + "\n    ")]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.description
              ? _c("div", { staticClass: "ce-field-description" }, [
                  _vm._v("\n    " + _vm._s(_vm.description) + "\n  "),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "ce-field-inputWrapper",
                class: { invalid: errors.length > 0 },
              },
              [
                _vm.type !== "textarea"
                  ? _c(
                      "input",
                      _vm._g(
                        {
                          ref: "input",
                          attrs: {
                            id: _vm.name,
                            type: _vm.activeInputType,
                            name: _vm.name,
                            placeholder: _vm.placeholder,
                            disabled: _vm.disabled,
                            autocomplete: _vm.autocomplete,
                            autocapitalize: _vm.autocapitalize,
                          },
                          domProps: { value: _vm.value },
                          on: {
                            input: function ($event) {
                              return _vm.$emit("input", $event)
                            },
                          },
                        },
                        _vm.$listeners
                      )
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.type === "password"
                  ? _c(
                      "i",
                      {
                        staticClass: "ce-field-passwordToggle",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.togglePasswordVisibility.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [
                        _vm.activeInputType === "password"
                          ? _c("fa-icon", { attrs: { icon: ["fas", "eye"] } })
                          : _c("fa-icon", {
                              attrs: { icon: ["fas", "eye-slash"] },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.type === "textarea"
                  ? _c(
                      "textarea",
                      _vm._g(
                        {
                          ref: "input",
                          attrs: {
                            id: _vm.name,
                            name: _vm.name,
                            placeholder: _vm.placeholder,
                            disabled: _vm.disabled,
                          },
                          domProps: { value: _vm.value },
                          on: {
                            input: function ($event) {
                              return _vm.$emit("input", $event)
                            },
                          },
                        },
                        _vm.$listeners
                      )
                    )
                  : _vm._e(),
              ]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }