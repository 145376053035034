var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        headline: _vm.$t("register.modalHeadline"),
        description: _vm.$t("register.modalDescription"),
      },
      on: { close: _vm.close },
    },
    [
      _c("div", { staticClass: "ce-registerForm" }, [
        _c(
          "div",
          { staticClass: "ce-modalContent ce-userRegistration-content" },
          [
            _vm.hasMessage
              ? _c("alert", {
                  attrs: {
                    message: _vm.message.content,
                    type: _vm.message.type,
                    closable: false,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.finished
              ? [
                  _c("p", { staticClass: "mt0" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("register.successContent")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt4" }, [
                    _c(
                      "button",
                      {
                        staticClass: "ce-btn ce-btn-light ce-btn--fullWidth",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.close.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("register.successCta")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]),
                ]
              : _vm._e(),
            _vm._v(" "),
            !_vm.finished
              ? [
                  _c(
                    "ValidationObserver",
                    { ref: "validationObserver", attrs: { tag: "div" } },
                    [
                      _c("validated-input", {
                        attrs: {
                          name: "firstName",
                          value: _vm.form.firstName,
                          label: _vm.$t("register.firstName"),
                          rules: "required",
                        },
                        on: { input: _vm.onInput },
                      }),
                      _vm._v(" "),
                      _c("validated-input", {
                        attrs: {
                          name: "lastName",
                          value: _vm.form.lastName,
                          label: _vm.$t("register.lastName"),
                          rules: "required",
                        },
                        on: { input: _vm.onInput },
                      }),
                      _vm._v(" "),
                      _c("validated-input", {
                        attrs: {
                          name: "company",
                          value: _vm.form.company,
                          label: _vm.$t("register.company"),
                          rules: "required",
                        },
                        on: { input: _vm.onInput },
                      }),
                      _vm._v(" "),
                      _c("validated-input", {
                        attrs: {
                          name: "email",
                          value: _vm.form.email,
                          label: _vm.$t("register.email"),
                          rules: "required|email",
                        },
                        on: { input: _vm.onInput },
                      }),
                      _vm._v(" "),
                      _c("country-select", {
                        attrs: {
                          name: "country",
                          rules: "required",
                          label: _vm.$t("countrySelect.label"),
                        },
                        model: {
                          value: _vm.form.country,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "country", $$v)
                          },
                          expression: "form.country",
                        },
                      }),
                      _vm._v(" "),
                      _c("ValidationProvider", {
                        staticClass: "ce-field",
                        attrs: {
                          name: _vm.$t("register.industry"),
                          rules: "required",
                          mode: "eager",
                          tag: "div",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ errors }) {
                                return [
                                  _c("label", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("register.industry")) +
                                        " "
                                    ),
                                    _c("span", [_vm._v("*")]),
                                    _vm._v(" "),
                                    errors.length > 0
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "ms-2 ml1 regular ce-colorError",
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(errors[0]) +
                                                "\n              "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "flex flex-wrap mxn2" },
                                    _vm._l(_vm.industries, function (industry) {
                                      return _c(
                                        "div",
                                        {
                                          key: industry.code,
                                          staticClass:
                                            "ce-userRegistration-industrySelect",
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.form.selectedIndustries,
                                                expression:
                                                  "form.selectedIndustries",
                                              },
                                            ],
                                            attrs: {
                                              type: "checkbox",
                                              id: `industry_${industry.code}`,
                                            },
                                            domProps: {
                                              value: industry.code,
                                              checked: Array.isArray(
                                                _vm.form.selectedIndustries
                                              )
                                                ? _vm._i(
                                                    _vm.form.selectedIndustries,
                                                    industry.code
                                                  ) > -1
                                                : _vm.form.selectedIndustries,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a =
                                                    _vm.form.selectedIndustries,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = industry.code,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.form,
                                                        "selectedIndustries",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.form,
                                                        "selectedIndustries",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "selectedIndustries",
                                                    $$c
                                                  )
                                                }
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                for: `industry_${industry.code}`,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(industry.title) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }),
                                    0
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          222054362
                        ),
                      }),
                      _vm._v(" "),
                      _c("password-field", {
                        attrs: {
                          name: "password",
                          value: _vm.form.password,
                          label: _vm.$t("register.password"),
                        },
                        on: { input: _vm.onInput },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "ce-userRegistration-consent ms-1 line-height-3 mb4",
                        },
                        [
                          _c("div", [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("register.consent.infoContent")) +
                                "\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "ce-userRegistration-consent-personalData",
                            },
                            [
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "register.consent.personalData.topInfo"
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "mt2" },
                                [
                                  _c(
                                    "checkbox",
                                    {
                                      staticClass: "ce-field",
                                      attrs: {
                                        id: "consent_inquiries",
                                        "vertical-centered": false,
                                      },
                                      model: {
                                        value: _vm.form.consent.inquiries,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.consent,
                                            "inquiries",
                                            $$v
                                          )
                                        },
                                        expression: "form.consent.inquiries",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t(
                                              "register.consent.personalData.inquiries"
                                            )
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "checkbox",
                                    {
                                      staticClass: "ce-field",
                                      attrs: {
                                        id: "consent_personalizedTracking",
                                        "vertical-centered": false,
                                      },
                                      model: {
                                        value:
                                          _vm.form.consent.personalizedTracking,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.consent,
                                            "personalizedTracking",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.consent.personalizedTracking",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t(
                                              "register.consent.personalData.personalizedTracking"
                                            )
                                          ) +
                                          "\n\n                  "
                                      ),
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "ce-userRegistration-consent-infoCta",
                                          attrs: {
                                            href:
                                              _vm.links
                                                .sunChemicalDataProtectionPage ??
                                              _vm.links.dataProtectionPage,
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              _vm.consentInfoContent = _vm.$t(
                                                "register.consent.dataProtectionInfo.personalizedTracking"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  "register.consent.dataProtectionInfo.label"
                                                )
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                      _vm._v(".\n                "),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "checkbox",
                                    {
                                      staticClass: "ce-field",
                                      attrs: {
                                        id: "consent_surveys",
                                        "vertical-centered": false,
                                      },
                                      model: {
                                        value: _vm.form.consent.surveys,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.consent,
                                            "surveys",
                                            $$v
                                          )
                                        },
                                        expression: "form.consent.surveys",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t(
                                              "register.consent.personalData.surveys"
                                            )
                                          ) +
                                          "\n\n                  "
                                      ),
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "ce-userRegistration-consent-infoCta",
                                          attrs: {
                                            href:
                                              _vm.links
                                                .sunChemicalDataProtectionPage ??
                                              _vm.links.dataProtectionPage,
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              _vm.consentInfoContent = _vm.$t(
                                                "register.consent.dataProtectionInfo.surveys"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  "register.consent.dataProtectionInfo.label"
                                                )
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                      _vm._v(".\n                "),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "checkbox",
                                    {
                                      staticClass: "ce-field",
                                      attrs: {
                                        id: "consent_onlineMarketing",
                                        "vertical-centered": false,
                                      },
                                      model: {
                                        value: _vm.form.consent.onlineMarketing,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.consent,
                                            "onlineMarketing",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.consent.onlineMarketing",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t(
                                              "register.consent.personalData.onlineMarketing"
                                            )
                                          ) +
                                          "\n\n                  "
                                      ),
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "ce-userRegistration-consent-infoCta",
                                          attrs: {
                                            href:
                                              _vm.links
                                                .sunChemicalDataProtectionPage ??
                                              _vm.links.dataProtectionPage,
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              _vm.consentInfoContent = _vm.$t(
                                                "register.consent.dataProtectionInfo.onlineMarketing"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  "register.consent.dataProtectionInfo.label"
                                                )
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                      _vm._v(".\n                "),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "register.consent.personalData.disclaimer"
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _vm.consentInfoContent
                                ? _c(
                                    "modal",
                                    {
                                      attrs: { "show-header": false },
                                      on: {
                                        close: function ($event) {
                                          _vm.consentInfoContent = null
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "ce-modalContent" },
                                        [
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.consentInfoContent
                                              ),
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "i18n",
                                                {
                                                  attrs: {
                                                    path: "register.consent.fullDataProtectionStatement.content",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "ce-userRegistration-consent-infoCta",
                                                      attrs: {
                                                        target: "_blank",
                                                        href:
                                                          _vm.links
                                                            .sunChemicalDataProtectionPage ??
                                                          _vm.links
                                                            .dataProtectionPage,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "register.consent.fullDataProtectionStatement.link"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "button",
                          {
                            staticClass:
                              "ce-btn ce-btn-light ce-btn--fullWidth",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.submit.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _vm.loading
                              ? [
                                  _c("fa-icon", {
                                    attrs: { icon: "spinner", spin: "" },
                                  }),
                                ]
                              : [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("register.registerCta")) +
                                      "\n              "
                                  ),
                                ],
                          ],
                          2
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mt2 ce-userRegistration-login" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("register.existingAccount")) +
                              " "
                          ),
                          _c(
                            "a",
                            {
                              attrs: { href: _vm.loginLink },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.$store.dispatch("user/showLogin")
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("register.loginCta")))]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }