import 'utils/icons';
import UserLoginModal from 'components/UserLoginModal.vue';
import UserRegistration from 'components/UserRegistration.vue';
import UserForgotPassword from 'components/UserForgotPassword.vue';
import UserMenu from 'components/UserMenu.vue';
import { cloneObject, requireConfirmation } from 'utils';
import logger from 'utils/logger';
import Modal from 'components/Modal.vue';
import MainMenu from 'components/menu/desktop/Menu.vue';
import MobileMenu from 'components/menu/mobile/Menu.vue';
import ContactForm from 'components/forms/ContactForm.vue';
import Alert from 'components/Alert.vue';
import CookieModal from 'components/CookieModal.vue';
import ChangeInitialPasswordModal from 'components/ChangeInitialPasswordModal.vue';
import Tooltip from 'components/Tooltip.vue';
import ComparisonBasket from 'components/product-comparison/ComparisonBasket.vue';
import notification from 'utils/noty';
import RequireCountryConfirmationModal from 'components/RequireCountryConfirmationModal.vue';
import BackToTopButton from 'components/BackToTopButton.vue';
import ChangedMySheetsHint from 'components/product-sheets/ChangedMySheetsHint.vue';
import PrivacyPolicyModal from 'components/PrivacyPolicyModal.vue';
import 'utils/validator';

logger.init();

/**
 * Convenience function to dispatch generic/trackEvent actions.
 *
 * @param {string} category e.g. "movie"
 * @param {string} action e.g. "rate"
 * @param {array|string|null|boolean|object} name e.g. "Iron Man"
 * @param {string|number|null} value 10
 */
const trackEvent = function trackEvent(category, action, name = null, value = null) {
  this.$store.dispatch('generic/trackEvent', {
    category, action, name, value
  });
};

export default {
  install(Vue) {
    Vue.prototype.$cloneObject = cloneObject;
    Vue.prototype.$trackEvent = trackEvent;
    Vue.prototype.$requireConfirmation = requireConfirmation;
    Vue.prototype.$notification = notification;
    Vue.config.productionTip = false;
    Vue.component('user-login-modal', UserLoginModal);
    Vue.component('user-registration', UserRegistration);
    Vue.component('user-forgot-password', UserForgotPassword);
    Vue.component('main-menu', MainMenu);
    Vue.component('user-menu', UserMenu);
    Vue.component('modal', Modal);
    Vue.component('main-menu', MainMenu);
    Vue.component('mobile-menu', MobileMenu);
    Vue.component('contact-form', ContactForm);
    Vue.component('alert', Alert);
    Vue.component('cookie-modal', CookieModal);
    Vue.component('change-initial-password-modal', ChangeInitialPasswordModal);
    Vue.component('tooltip', Tooltip);
    Vue.component('comparison-basket', ComparisonBasket);
    Vue.component('require-country-confirmation-modal', RequireCountryConfirmationModal);
    Vue.component('back-to-top-button', BackToTopButton);
    Vue.component('changed-my-sheets-hint', ChangedMySheetsHint);
    Vue.component('privacy-policy-modal', PrivacyPolicyModal);
  }
};
