import acl from '@sun-chemical/system-config/access-level';
import ConfirmationModal from 'components/ConfirmationModal.vue';
import logger from './logger';

const cloneObject = (obj) => JSON.parse(JSON.stringify(obj));
const getRange = (start, end) => [...Array(end - start + 1).keys()].map((i) => i + start);

/**
 * Check if the passed click event should close an open element.
 *
 * Default is true, false only if the passed notCloseForClass is in the event target path.
 *
 * @param {MouseEvent} e
 * @param {string} notCloseForClass
 * @return {boolean}
 */
const shouldCloseOnClick = (e, notCloseForClass) => {
  let el = e.target;

  if (!el) return true;

  while (el.nodeName !== 'BODY') {
    if (el.classList && el.classList.contains(notCloseForClass)) {
      return false;
    }

    el = el.parentElement;

    // Break if the parentElement is null, this can happen if the the click was inside an
    // element which is hidden right after the click
    if (!el) return true;
  }

  return true;
};

/**
 * Try to get the keycode of a given event.
 *
 * @param event
 * @return {null|number|*}
 */
const getEventKeyCode = (event) => event.keyCode;

/**
 * Check if the given user roles allow to view the given accessLevel.
 *
 * @param {String} accessLevel
 * @param {Array|null} roles
 * @return {boolean}
 */
const checkPermission = (accessLevel, user = null) => {
  // Public, so always true
  if (accessLevel === acl.PUBLIC) return true;

  // No user logged in, so everything besides public is false
  if (!user) return false;

  const { roles } = user.tokenPayload;

  // User has no roles, so only public_restricted is allowed
  if (!roles) return accessLevel === acl.PUBLIC_RESTRICTED;

  // Always true for internal users
  if (roles.indexOf(acl.INTERNAL) > -1) return true;

  // True for restricted
  if (accessLevel === acl.RESTRICTED) return true;

  // Check for public_restricted
  if (accessLevel === acl.PUBLIC_RESTRICTED && roles.indexOf(acl.PUBLIC_RESTRICTED) > -1) return true;

  return false;
};

const arrayCompareFunction = (key, order = 'asc') => (a, b) => {
  let varA = key.split('.').reduce((o, i) => o[i], a);
  if (typeof varA === 'string') varA = varA.toUpperCase();

  let varB = key.split('.').reduce((o, i) => o[i], b);
  if (typeof varB === 'string') varB = varB.toUpperCase();

  if (!varA && !varB) return 0;

  let comparison = 0;

  if (!varA) comparison = -1;
  if (!varB) comparison = 1;

  if (varA > varB) comparison = 1;
  else if (varA < varB) comparison = -1;

  return order === 'desc' ? comparison * -1 : comparison;
};

const sortArray = (array, key, order = 'asc') => array.sort(arrayCompareFunction(key, order));

/**
 * Require a user confirmation using the ConfirmationModal component.
 *
 * @param {string} headline
 * @param {Store}  store The current store instance, e.g. "this" if called from vuex action
 * @param {object} data Object with optional data
 * @param {string} data.description Description for the modal
 * @param {string} data.resolveCta Custom label for the resolveCta
 * @param {string} data.rejectCta Custom label for the rejectCta
 * @param {boolean} data.disableReject Disables the reject button, so only resolve is possible
 * @return {Promise<unknown>}
 */
const requireConfirmation = (headline, data = {}, store) => new Promise((resolve, reject) => {
  const propsData = {
    headline,
    resolve,
    reject
  };

  if (data && Object.keys(data).length > 0) {
    ['description', 'resolveCta', 'rejectCta', 'disableReject'].forEach((optionalField) => {
      if (data[optionalField]) {
        propsData[optionalField] = data[optionalField];
      }
    });
  }

  const modal = new ConfirmationModal({ store, propsData }).$mount();
  document.getElementById('app').appendChild(modal.$el);
});

/**
 * Log given messages to the console but only on development environment.
 *
 * @param messages
 */
const debugMsg = (...messages) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log('[Debug]', ...messages);
  }
};

/**
 * Format a size given in bytes.
 *
 * @param bytes
 * @param separator
 * @param postFix
 * @return {string}
 *
 * @see https://gist.github.com/lanqy/5193417#gistcomment-3240729
 */
const formatByteSize = (bytes, separator = '', postFix = '') => {
  if (bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.min(parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10), sizes.length - 1);
    return `${(bytes / (1024 ** i)).toFixed(i ? 1 : 0)}${separator}${sizes[i]}${postFix}`;
  }
  return 'n/a';
};

const chunk = (arr, size) => Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size));

export {
  cloneObject, shouldCloseOnClick, getEventKeyCode, getRange, checkPermission,
  logger, sortArray, requireConfirmation, debugMsg, formatByteSize, chunk,
};
