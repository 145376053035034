import axios from 'axios';
import qs from 'qs';

export default class UserService {
  constructor(user = null) {
    this.apiClient = axios.create({
      baseURL: `${location.origin}`
    });

    this.userServiceClient = axios.create({
      baseURL: `${payload.services.userservice.url}/api/v1`
    });

    if (user) this.setUser(user);
  }

  setUser(user) {
    this.user = user;

    this.apiClient.defaults.headers.common.Authorization = `Bearer ${this.user.token}`;
    this.userServiceClient.defaults.headers.common.Authorization = `Bearer ${this.user.token}`;
  }

  async getUserInfo() {
    return (await this.userServiceClient.get('/user/me')).data;
  }

  async getLogEntries(logType, options = {}) {
    const params = {
      type: logType
    };

    ['prd', 'limit'].forEach((o) => {
      if (options[o]) params[o] = options[o];
    });

    return (await this.userServiceClient.get(`/user/me/log?${qs.stringify(params)}`)).data;
  }

  login(email, password) {
    return new Promise((resolve, reject) => {
      this.apiClient
        .post('/login', {
          email,
          password
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => reject(error));
    });
  }

  async register({
    firstName, lastName, company, email, country, selectedIndustries, password, consent
  }) {
    try {
      const response = await this.userServiceClient.post('/user/register', {
        firstName,
        lastName,
        company,
        email,
        country,
        selectedIndustries,
        password,
        consent,
        origin: 'service-platform'
      });

      return response.userId;
    } catch (e) {
      if (e.response?.data?.errorMessage) {
        return Promise.reject(e.response.data.errorMessage);
      }

      if (e.response?.data?.message) {
        return Promise.reject(e.response.data.message);
      }

      return Promise.reject(e.message);
    }
  }

  async update(data) {
    try {
      await this.userServiceClient.put('/user/me', data);
    } catch (e) {
      await Promise.reject(e);
    }
  }

  async changeEmail(newEmail) {
    try {
      await this.userServiceClient
        .put('/user/me/changeEmail', { newEmail });
    } catch (e) {
      await Promise.reject(e);
    }
  }

  async requestValidation(data) {
    try {
      await this.userServiceClient
        .put('/user/me/validationRequest', data);
    } catch (e) {
      await Promise.reject(e);
    }
  }

  async acceptPrivacyPolicy(data) {
    try {
      return (await this.userServiceClient
        .put('/user/me/privacy-policy', data)).data;
    } catch (e) {
      await Promise.reject(e);
      return null;
    }
  }

  async requestPasswordReset(email) {
    try {
      await this.userServiceClient
        .post('/user/forgot-password', {
          email
        });
    } catch (e) {
      await Promise.reject(e);
    }
  }

  async resetPassword(token, password) {
    try {
      await this.userServiceClient
        .post('/user/reset-password', {
          password
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
    } catch (e) {
      await Promise.reject(e);
    }
  }

  async resendVerificationEmail(email) {
    try {
      await this.userServiceClient
        .post('/user/send-email-verification', {
          email
        });
    } catch (e) {
      await Promise.reject(e);
    }
  }
}
