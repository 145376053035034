import Vue from 'vue';
import {
  ValidationProvider, ValidationObserver, extend, setInteractionMode
} from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import en from 'vee-validate/dist/locale/en';
import ValidatedInput from 'components/ValidatedInput.vue';
import ValidatedSelect from 'components/ValidatedSelect.vue';
import ValidatedMultiselect from 'components/ValidatedMultiselect.vue';
import CountrySelect from 'components/forms/CountrySelect.vue';
import { translate } from 'utils/i18n';

// Set interaction mode, @see https://logaretm.github.io/vee-validate/guide/interaction-and-ux.html#eager
setInteractionMode('eager');

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('validated-input', ValidatedInput);
Vue.component('validated-select', ValidatedSelect);
Vue.component('validated-multiselect', ValidatedMultiselect);
Vue.component('country-select', CountrySelect);

// Load only the required validation rules
['required', 'email', 'min', 'max', 'numeric'].forEach((ruleName) => {
  extend(ruleName, {
    ...rules[ruleName],
    message: en.messages[ruleName]
  });
});

extend('passwordconfirmation', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: translate('validation.password.confirmationMismatch')
});

extend('password', {
  params: ['min'],
  validate(value, { min }) {
    if (!min) {
      min = 8;
    }

    if (!value || typeof value !== 'string') {
      return false;
    }

    if (value.length < 8) {
      return false;
    }

    if (!value.match(/\d/)) {
      return false;
    }

    if (!value.match(/[a-z|A-Z]/)) {
      return false;
    }

    return true;
  }
});

Vue.mixin({
  methods: {
    onInput(event) {
      let value = null;
      if (event.target.type === 'checkbox') value = event.target.checked;
      else if (event.target.type === 'number') value = parseInt(event.target.value);
      else value = event.target.value;

      let target = this.form;
      const parts = event.target.name.split('.');
      const key = parts.pop();

      if (parts.length > 0) {
        parts.forEach((part) => {
          target = target[part];
        });
      }

      target[key] = value;
    }
  }
});
